import {GsdbContactsId} from "./gsdb-contacts-id.model";

export class GsdbContactsMain {
  public id: GsdbContactsId = new GsdbContactsId("", "")
  public companyName: String = "";
  public siteCode: String = "";
  public prefTitle: String = "";
  public firstName: String = "";
  public lastName: String = "";
  public email: String = "";
  public telephone: String = "";
  public mailAddress: String = "";
  public cityTown: String = "";
  public stateProvince: String = "";
  public zipPostalCode: String = "";
  public telCountryCode: String = "";
  public faxCountryCode: String = "";
  public fax: String = "";
  public buildingSuite: String = "";
  public county: String = "";
  public country: String = "";
  public altContactLastName: String = "";
  public altContactFirstName: String = "";
  public altContactCountryCode: String = "";
  public altContactTelephone: String = "";
  public lastUpdated: String = "";
  public lastUpdatedBy: String = "";
  public detailsComplete: string = ""
}
