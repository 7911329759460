import {Component, OnInit} from '@angular/core';
import {GsdbContactsMain} from "../../../models/perl-migration-web/gsdb-contacts-main/gsdb-contacts-main.model";
import {Router} from "@angular/router";
import {GsdbContactsService} from "../service/gsdb-contacts.service";

@Component({
  selector: 'app-gsdb-contacts-kfc',
  templateUrl: './gsdb-contacts-kfc.component.html',
  styleUrls: ['./gsdb-contacts-kfc.component.scss']
})
export class GsdbContactsKfcComponent implements OnInit {
  contactDetails!: GsdbContactsMain;
  valid: boolean = false;
  gsdbCode: String='';

  constructor(private router: Router, private apiService: GsdbContactsService) {

  }

  ngOnInit(): void {
    this.getcontactDetails();
  }

  update(values: any) {
    this.createUpdateRequest(values);
    this.apiService.updateContactDetails(this.contactDetails).subscribe((data: any) => {
      const successData = true;
      this.router.navigate(['/perlMigrationWeb/gsdbContactUpdate', "kfc"], {state:{successData}});
    }, error => {
      const successData = false;
      this.router.navigate(['/perlMigrationWeb/gsdbContactUpdate', "kfc"], {state:{successData}});
    });
  }

  getcontactDetails() {
    this.apiService.getContactDetails("kfc").subscribe((data: GsdbContactsMain) => {
      this.valid= true;
      if(data){
        this.contactDetails = data;
        this.gsdbCode = this.contactDetails?.siteCode;
      }else{
        this.contactDetails = new GsdbContactsMain()
      }
    },error=>{
      console.log(error);
      this.valid=false;
      this.router.navigate(['/perlMigrationWeb/gsdbContactsError']);
    })
  }


  createUpdateRequest(values: any) {
    this.contactDetails.firstName = values?.exeFirstName;
    this.contactDetails.lastName = values?.exeLastName;
    this.contactDetails.prefTitle = values?.prefTitle;
    this.contactDetails.email = values?.email;
    this.contactDetails.telCountryCode = values?.businessCountryCd;
    this.contactDetails.telephone = values?.businessTel;
    this.contactDetails.faxCountryCode = values?.faxCountryCd;
    this.contactDetails.fax = values?.faxTel;
    this.contactDetails.mailAddress = values?.mailAddr;
    this.contactDetails.buildingSuite = values?.officeSuite;
    this.contactDetails.cityTown = values?.city;
    this.contactDetails.country = values?.country;
    this.contactDetails.stateProvince = values?.state;
    this.contactDetails.zipPostalCode = values?.postalCode;
    this.contactDetails.country = values?.selCountry;
    this.contactDetails.altContactFirstName = values?.emergencyFirstName;
    this.contactDetails.altContactLastName = values?.emergencyLastName;
    this.contactDetails.altContactCountryCode = values?.emeContactCountryCd;
    this.contactDetails.altContactTelephone = values?.emeContactTel;
    if(this.contactDetails.id.positionCode.length==0){
      this.contactDetails.id.positionCode="9";
    }
  }
}
