<app-common-header></app-common-header>
<div class="page-title">
  <img src="./assets/gsdb3b.gif"/>
</div>
<div class="main-container">
  <div class="flex justify-content-between">
    <p class="ml-2 mt-2 color font-bold" *ngIf="success">Your contact information was successfully updated.</p>
    <p class="ml-2 mt-2 color font-bold text-danger" *ngIf="!success">Errors Encountered</p>
    <p routerLink="/support" class="mr-2 mt-2 help-btn">Help</p>
  </div>
  <div class="body flex-1">
    <p class="heading text-center" *ngIf="type==='chief' && success">Contact Information for Chief Financial Officer was
      Updated</p>
    <p class="heading text-center" *ngIf="type==='vp' && success">Contact Information for Vice President of Quality was Updated</p>
    <p class="heading text-center" *ngIf="type==='ceo' && success">Contact Information for CEO/President was Updated</p>
    <p class="heading text-center" *ngIf="type==='ford' && success">Contact Information for Ford Account Manager was Updated</p>
    <p class="heading text-center" *ngIf="type==='iso' && success">Contact Information for Chief Information Security Officer</p>
    <p class="heading text-center" *ngIf="type==='exeCeo' && success">Contact Information for Executive Assistant to CEO</p>
    <p class="heading text-center" *ngIf="type==='vpSales' && success">Contact Information for Vice President of Sales</p>
    <p class="heading text-center" *ngIf="type==='vsml' && success">Contact Information for	Value Stream Mapping Lead </p>
    <p class="heading text-center" *ngIf="type==='kfc' && success">Contact Information for Key Ford Contact</p>
    <p class="heading text-center text-danger" *ngIf="!success">Errors Encountered: Failed to update the contact details.</p>
    <div class="flex">
      <app-gsdb-contacts-sidebar></app-gsdb-contacts-sidebar>
      <div class="right-side ml-4" *ngIf="success">
        <p>Your contact information was successfully updated.</p>
        <p>Please choose another job title on the left-hand side to enter or update contact information for.</p>
      </div>
      <div class="right-side ml-4" *ngIf="!success">
        <p>Errors Encountered: Failed to update the contact details.</p>
        <p>Please try again after sometime or contact the site administrator or support team.</p>
      </div>
    </div>
  </div>
  <hr class="mb-0"/>
  <div class="mb-4 text-sm footer">
    <a class="mt-0 mr-2 navigation"
       href="https://e3.ford.com/eRoom/fordna7/FSN/Contacts%20Database/HTML%20Screens/template.html#Top">Top</a> © 1998,
    Ford Motor Company
  </div>
</div>
